<template>
	<div class="specialis">
	<div class="flex">
	<div class="wscreen hscreen">
		<div class="hfull wfull" id="map" v-resize="resizeMap">
		</div>
		<div class="map-overlay" id="features">
			<h2>Terület</h2>
			<div id="pd"><p>0</p></div>
		</div>
		<div class="map-overlay" id="legend"></div>		
	</div>
	</div>
	</div>
</template>

<script>

// import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ref, onMounted } from "vue";

export default {
	setup() {
		let map = ref(null)
		onMounted(() => {
			mapboxgl.accessToken = "pk.eyJ1IjoiYXRsbyIsImEiOiJjam5mdDVldmkwbTJ6M3BwNW13ZXhuYXM4In0.gikvkhPkud0GJy1FxG6-Zg";
			map.value = new mapboxgl.Map({
				container: "map",
				// style: "mapbox://styles/atlo/cko8mg6wo593d18n5hco8nuzq",
				style: "mapbox://styles/csomix/cl2ovvwtt001315qmvjiceu5f",
				center: [19.529, 47.103],
				zoom: 6.5,
			});
			map.value.addControl(new mapboxgl.NavigationControl(), 'top-right'); 
			// map.value.on('load', () => {
/*				if (this.$router.currentRoute.value.name == "map-points") {
					// console.log ("MS: ", this.market1)
					if (! this.market1) {
						// console.log ("MARKET-1");
						this.marker1 = new mapboxgl.Marker()
							.setLngLat([19.529, 47.103])
							.addTo(map);
						this.market1 = "MARKET";
						// console.log ("MK: ", this.market1)
					}
				}
*/				
			// });
		});
		return {
			map
		};
	},
	data() {
		return {
			loading: false,
			access_token: "pk.eyJ1IjoiYXRsbyIsImEiOiJjam5mdDVldmkwbTJ6M3BwNW13ZXhuYXM4In0.gikvkhPkud0GJy1FxG6-Zg",
			center: [19.529, 47.103],
			// map: {},
			market1: null
		};
	},
	created() {
		// // console.log ("MAP", this.map)
		// // console.log ("CREATED: ", this.$router.currentRoute.value.name)
	},
	mounted() {
		this.map.on('load', () => {
			this.createPoints();
			this.createLines();
			this.createPolygons();
			this.createSpecialMAP();
		});
		// define layer names
		const layers = [
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'10',
			'11',
			'12',
			'13',
			'14',
			'15',
			'16',
			'17',
			'18',
			'19',
			'20'
		];

		const colors = [
			'rgb(255,255,176)',
			'rgb(255,240,128)',
			'rgb(255,224,112)',
			'rgb(250,208,96)',
			'rgb(240,192,70)',
			'rgb(240,160,48)',
			'rgb(224,144,48)',
			'rgb(208,128,32)',
			'rgb(208,96,32)',
			'rgb(192,80,32)',
			'rgb(176,64,16)',
			'rgb(142,48,16)',
			'rgb(128,32,0)',
			'rgb(112,16,0)',
			'rgb(96,0,0)',
			'rgb(70,0,0)',
			'rgb(64,0,0)',
			'rgb(48,0,0)',
			'rgb(24,0,0)',
			'rgb(0,0,0)',
		];
		
		// create legend
		const legend = document.getElementById('legend');
		
		layers.forEach((layer, i) => {
			const color = colors[i];
			const item = document.createElement('div');
			const key = document.createElement('span');
			key.className = 'legend-key';
			key.style.backgroundColor = color;
			
			const value = document.createElement('span');
			value.innerHTML = `${layer}`;
			item.appendChild(key);
			item.appendChild(value);
			legend.appendChild(item);
		});

		// // console.log ("MOUNTED: ", this.$router.currentRoute.value.name)
	},
	activated() {
		// // console.log ("ACTIVATED: ", this.$router.currentRoute.value.name)
	},
	updated() {
		this.createPoints();
		this.createLines();
		this.createPolygons();
		this.createSpecialMAP();
		// // console.log ("UPDATED: ", this.$router.currentRoute.value.name)
	},
	methods: {
		createLines() {
			// // console.log ("CL START")
			if (this.$router.currentRoute.value.name == 'map-lines') {
				// // console.log ("CL ROUTE")
				if (typeof this.map.getLayer("lines") === 'undefined')  {
					// // console.log ("CL UNDEFINED")
					if (typeof this.map.getSource("lines") === "undefined" ) {
						// // console.log ("LOAD SOURCE LINES")
						this.map.addSource('lines', {
							type: 'geojson',
							data: '/backend/linesList'
						});
						// // console.log ("ADD LAYER LINES")
						this.map.addLayer({
							"id": "lines",
							"source": "lines",
							'type': 'line',
							'layout': {
								'visibility': 'visible',
							},
							paint: { 
								'line-width': 4, 
								'line-color': "#FF0000",
							},					
						});
					}
				} else {
					if (this.map.getLayoutProperty("lines", "visibility") == 'visible') {
						this.map.setLayoutProperty("lines", 'visibility', 'none')
					} else {
						this.map.setLayoutProperty("lines", 'visibility', 'visible')
					}
				}
			}			
			// console.log ("CL END")
		},
		createPoints() {
			// console.log ("CP START")
			if (this.$router.currentRoute.value.name == 'map-points') {
				if (! this.market1) {
					this.market1 = "POINTS LOAD";
					this.map.loadImage("/img/custom_marker.png", (error, image) => {
						if (error) throw error;
						this.map.addImage('custom-marker', image);
						this.map.addSource('points', {
							type: 'geojson',
							data: '/backend/pointsList'
						});
						// console.log ("ADD LAYER POINTS")
						this.map.addLayer({
							'id': 'points',
							'type': 'symbol',
							'source': 'points',
							'layout': {
								'icon-image': 'custom-marker',
								'text-field': ['get', 'Type'],
								'text-font': [
									'Open Sans Semibold',
									'Arial Unicode MS Bold'
								],
								'text-offset': [0, 1.25],
								'text-anchor': 'top',
							},
							paint: {
								"text-color": "#ffffff"
							}
						});
					});
				} else {
					if (this.map.getLayoutProperty("points", "visibility") == 'visible') {
						this.map.setLayoutProperty("points", 'visibility', 'none')
					} else {
						this.map.setLayoutProperty("points", 'visibility', 'visible')
					}
				}
			}
			// console.log ("CP END")
		},
		createPolygons() {
			// console.log ("CM START")
			if (this.$router.currentRoute.value.name == 'map-polygons') {
				if (typeof this.map.getLayer("polygons") === 'undefined')  {
					if (typeof this.map.getSource("polygons") === "undefined" ) {
						// console.log ("LOAD SOURCE LINES")
						this.map.addSource('polygons', {
							type: 'geojson',
							data: '/backend/polygonsList'
						});
						// console.log ("ADD LAYER POLYGONS")
						this.map.addLayer({
							'id': 'polygons',
							'type': 'fill',
							'source': 'polygons',
							'layout': {
								'visibility': 'visible',
							},
							'paint': {
								'fill-color': '#008000',
								'fill-opacity': 0.8
							}
						});
						this.map.addLayer({
							'id': 'outline',
							'type': 'line',
							'source': 'polygons',
							'layout': {
								'visibility': 'visible',
							},
							'paint': {
								'line-color': '#000',
								'line-width': 3
							}
						});
					}
				} else {
					if (this.map.getLayoutProperty("polygons", "visibility") == 'visible') {
						this.map.setLayoutProperty("polygons", 'visibility', 'none')
						this.map.setLayoutProperty("outline", 'visibility', 'none')

					} else {
						this.map.setLayoutProperty("polygons", 'visibility', 'visible')
						this.map.setLayoutProperty("outline", 'visibility', 'visible')
					}
				}					
			}			
			// console.log ("CM END")
		},
		createMap() {
			// console.log ("CMAP");
		},
		mapCurrent() {
		},
		createCityLogos() {
			// console.log ("CiL START")
			if (this.$router.currentRoute.value.name == 'map-city-logos') {
				var cityLayer = "map-city-logos"
				if (typeof this.map.getLayer(cityLayer) === 'undefined')  {
					if (typeof this.map.getSource(cityLayer) === "undefined" ) {
						this.map.addSource(cityLayer, {
							type: 'geojson',
							data: '/backend/cityLayer'
						});
						this.map.addLayer({
							'id': cityLayer,
							'type': 'fill',
							'source': cityLayer,
							'layout': {
								'visibility': 'visible',
							},
							'paint': {
								'fill-color': '#805512',
								'fill-opacity': 0.2
							}
						});
					}
				} else {
					if (this.map.getLayoutProperty(cityLayer, "visibility") == 'visible') {
						this.map.setLayoutProperty(cityLayer, 'visibility', 'none')
					} else {
						this.map.setLayoutProperty(cityLayer, 'visibility', 'visible')
						this.map.setLayoutProperty(cityLayer, 'visibility', 'visible')
					}
				}
			}
			// console.log ("CiL END")
		},
		searchMenu(items, search) {
			if (items === undefined) {
				return null;
			}
			for (var i=0;i<items.length;i++) {
				if (items[i].idinfo == search) {
					return items[i];
				} else {
					if (items[i].items !== undefined) {
						var ret = this.searchMenu(items[i].items, search)
						if (ret != null) {
							return ret;
						}
					}
				}
			}
			return null;
		},
		searchMenuPolygon() {
			return this.searchMenu(this.model, 'map');
		},
		createSpecialMAP() {
			// console.log ("SM START")
			if (this.$router.currentRoute.value.name == 'map-special') {
				// console.log ("SM - MAP-SPECIAL")
				var startmenu = this.searchMenuPolygon();
				// console.log ("SM MENU", startmenu)
				for (var i=0;i<startmenu.items.length;i++) {
					// console.log("SM - ",i, startmenu.items[i].label )
					// // console.log ("L: ", this.model[0].items[1].items[i].label, "ID:", this.model[0].items[1].items[i].infoid)	
					if (startmenu.items[i].infoid !== undefined) {
						// // console.log ("INFO2:", this.model[0].items[1].items[i].infovalue, layername)
						// var info = JSON.parse(startmenu.items[i].infovalue)
						// console.log ("INFO-START",startmenu.items[i])
						let infoid = startmenu.items[i].infoid
						let agglevelid = startmenu.items[i].agglevelid.code
						let yearid = startmenu.items[i].yearid.code
						let label = startmenu.items[i].label
						// console.log ("INFO-START-2", infoid, agglevelid, yearid, label)
						let layername = "map-"+infoid;
						/*+ "-" + yearid + "-" + agglevelid; */
						let sourcename = "map-"+agglevelid
						// console.log ("INFO-START-S-L", sourcename, layername)
						// console.log ("SM - LAYER:", layername, "SOURCE:", sourcename)
						if (typeof this.map.getLayer(layername) === 'undefined')  {
							// console.log ("SM - UL")
							if (typeof this.map.getSource(sourcename) === "undefined" ) {
								// console.log ("SM - US")
								/*
								if (localStorage.getItem(sourcename)) {
									try {
										var data = JSON.parse(localStorage.getItem(sourcename));
										this.map.addSource(sourcename, {
											type: 'geojson',
											data: data
										});
									} catch(e) {
										localStorage.removeItem(sourcename);

										this.map.addSource(sourcename, {
											type: 'geojson',
											data: '/backend/polygonsFromList/'+agglevelid,
										});
										// console.log("SM - SM(2):",this.map.getSource(sourcename).data)

									}								
								} else {
									fetch('/backend/polygonsFromList/'+agglevelid)
									.then(response => response.json())
									.then(data => {
										this.map.addSource(sourcename, {
											type: 'geojson',
											data: data
										});
										localStorage.setItem(sourcename, JSON.stringify(data));
									})
									.catch((error) => {
										console.error('Error:', error);
									});
									// console.log ("FM - FS(2)")
								}
								*/
								this.map.addSource(sourcename, {
									type: 'geojson',
									data: '/backend/polygonsFromList/'+agglevelid,
								});

								// console.log ("SM - FD")
								fetch('/backend/tstarData/'+yearid+'/'+agglevelid+'/'+infoid)
									.then(response => response.json())
									.then(data => {
									// console.log(data)
									if (data != null && data.message == null) {
										const matchExpression = ['match', ['get', 'individualid']];
										for (const row of data.data) {
											/*
											const green = row['value'];
											const color = `rgb(${green}, ${green}, 0)`;
											*/
											const skala = row['skala']-1;
											// // console.log ("SKALA", skala)
											const red=data.colors[skala].red
											const green=data.colors[skala].green
											const blue=data.colors[skala].blue

											// // console.log ("COLOS", red, blue, green)
											const color = `rgb(${red}, ${green}, ${blue})`;

											matchExpression.push(row['individualid'], color);
										}
										matchExpression.push('rgba(0, 0, 0, 0)');
										// console.log ("SM ADD LAYER:", layername)
										if (this.map.getLayer(layername)) {
											this.map.removeLayer(layername);
										}
										this.map.addLayer(
											{
												'id': layername,
												'type': 'fill',
												'source': sourcename,
												'paint': {
													'fill-color': matchExpression
												},
												'layout': {
													'visibility': 'none',
												},
											},
										);
									}
								})
								.catch((error) => {
									console.error('Error:', error);
								});
							} else {
								// console.log ("SM - FD (2)")
								fetch('/backend/tstarData/'+yearid+'/'+agglevelid+'/'+infoid)
									.then(response => response.json())
									.then(data => {
									// console.log(data)
									if (data != null && data.message == null) {
										const matchExpression = ['match', ['get', 'individualid']];
										for (const row of data.data) {
											const skala = row['skala']-1;
											// // console.log ("SKALA", skala)
											const red=data.colors[skala].red
											const green=data.colors[skala].green
											const blue=data.colors[skala].blue
											const color = `rgb(${red}, ${green}, ${blue})`;
											matchExpression.push(row['individualid'], color);
										}
										matchExpression.push('rgba(0, 0, 0, 1)');
										// console.log ("SM ADD LAYER:", layername)
										if (this.map.getLayer(layername)) {
											this.map.removeLayer(layername);
										}
										this.map.addLayer(
											{
												'id': layername,
												'type': 'fill',
												'source': sourcename,
												'paint': {
													'fill-color': matchExpression
												},
												'layout': {
													'visibility': 'none',
												},
											},
										);
									}
								})
								.catch((error) => {
									console.error('Error:', error);
								});

							}
						} else {
							// console.log ("MAP LAYER EXISTS????")
							if (this.map.getLayoutProperty(layername, "visibility") == 'visible') {
								this.map.setLayoutProperty(layername, 'visibility', 'none')
							} else {
								this.map.setLayoutProperty(layername, 'visibility', 'visible')
								this.map.setLayoutProperty(layername, 'visibility', 'visible')
							}
						}
					} else {
						// console.log ("MAP EMPTY....")
					}
				}
			}
			// console.log ("SM END")
		},
		async watchRouter() {
			// // console.log("R:", this.$router.currentRoute.value.name)
			// // console.log ("MAP2:", this.map)
		},
		resizeMap() {
			this.map.resize();
			// // console.log("resize")
		}

	},
    props: {
        model: Array
    },
	watch: {
		'$myMapDelete.id'(value) {
			if (this.map.getLayer('map-'+value)) {
				this.map.removeLayer('map-'+value);
			}
			// console.log ("DELETE ROW - MAP - NEZD - NEZD!!!!:", value);
		},
		'$myMapView.id'(value) {
			// console.log ("MYMAPVIEW START:", value)
			if (value != null && value.length > 0) {
				var info = value.split(";") 
				var layername = 'map-'+info[0]
				// console.log ("MYMAPVIEW Layername:", layername)
				if (this.map.getLayer(layername)) {
					// console.log ("MYMAPVIEW EXISTS")
					if (this.map.getLayoutProperty(layername, "visibility") == 'visible') {
						this.map.setLayoutProperty(layername, 'visibility', 'none')
						// console.log ("MYMAPVIEW Layer NONE")
					} else {
						this.map.setLayoutProperty(layername, 'visibility', 'visible')
						// console.log ("MYMAPVIEW Layer VISIBLE")
					}
				}
			}
			// console.log ("MYMAPVIEW END");
		},
		'$myMapChange.id'(value) {
			// console.log ("MC START:", value)
			if (value != null && value.length > 0) {
				var startmenu = this.searchMenuPolygon();
				// console.log ("MC:", startmenu)
				var arrayInfo = value.split("|")
				for (var i=0;i<startmenu.items.length;i++) {
					// console.log("MC - ",i, startmenu.items[i].label )
					if (startmenu.items[i].infoid == arrayInfo[0]) {
						let infoid = startmenu.items[i].infoid
						let agglevelid = startmenu.items[i].agglevelid.code
						let yearid = startmenu.items[i].yearid.code
						let label = startmenu.items[i].label
						let layername = "map-"+infoid;
						let sourcename = "map-"+agglevelid
						let infoitem = startmenu.items[i];
						infoitem.load = true;
						if (typeof this.map.getSource(sourcename) === "undefined" ) {
							this.map.addSource(sourcename, {
								type: 'geojson',
								data: '/backend/polygonsFromList/'+agglevelid,
							});
						}
						fetch('/backend/tstarData/'+yearid+'/'+agglevelid+'/'+infoid)
							.then(response => response.json())
							.then(data => {
							// console.log("DATA:", data)
							if (data != null && data.message == null) {
								const matchExpression = ['match', ['get', 'individualid']];
								for (const row of data.data) {
									let skala = row['skala']-1;
									if (skala > 19) { skala = 19; }
									if (skala < 0){ skala = 0; }
									// // console.log ("SKALA: ", skala);
									const red=data.colors[skala].red
									const green=data.colors[skala].green
									const blue=data.colors[skala].blue
									const color = `rgb(${red}, ${green}, ${blue})`;
									matchExpression.push(row['individualid'], color);
								}
								matchExpression.push('rgba(0, 0, 0, 1)');
								// console.log ("SM ADD LAYER:", layername)
								if (this.map.getLayer(layername)) {
									this.map.removeLayer(layername);
								}
								this.map.addLayer(
									{
										'id': layername,
										'type': 'fill',
										'source': sourcename,
										'paint': {
											'fill-color': matchExpression
										},
										'layout': {
											'visibility': 'visible',
										},
									},
								);
								infoitem.data = false;
							} else {
								infoitem.data = true;
							}
							infoitem.load = false;

						})
						.catch((error) => {
							console.error('Error-Error-Error-Error:', error);
							infoitem.load = false;
							infoitem.data = true;
						});
					}
				}
			}
		},
	},
};
</script>

<style lang="scss">
// @import "mapbox-gl/dist/mapbox-gl.css";

/*
#map {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/

.wscreen {
  width: 100vw;
}

.hscreen {
  height: 100vh;
}
.hfull {
  height: 100%;
}

.wfull {
  width: 100%;
}

.specialis {
	padding: -2rem;
	overflow: hidden;
}
/*
.layout-wrapper .layout-main .layout-content {
	padding: 0px;
	overflow: hidden;
}
*/
.map-overlay {
	position: absolute;
	bottom: 0;
	right: 0;
/*	background: #fff; */
	margin-right: 20px;
	font-family: Arial, sans-serif;
	overflow: auto;
	border-radius: 3px;
}

#features {
	top: 50;
	height: 100px;
	margin-top: 20px;
	width: 250px;
	display: none;
}

#legend {
	padding: 10px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	line-height: 18px;
	/* height: 200px; */
	height: auto;
	margin-bottom: 40px;
	width: 80px;
}

#legend div span {
	color: rgba(255, 255, 255, 0.87);
	font-size: 16px;
}

.legend-key {
	display: inline-block;
	border-radius: 20%;
	width: 10px;
	height: 10px;
	margin-right: 5px;
}

</style>